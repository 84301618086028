<template>
  <div>
    <v-btn icon @click="updatePreferences()">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="preferencesSt && preferencesSt.showKanbanFirst"
            v-on="on"
          >
            mdi-table
          </v-icon>
          <v-icon v-else v-on="on">mdi-trello</v-icon>
        </template>
        <span v-if="preferencesSt && preferencesSt.showKanbanFirst">
          Tabla
        </span>
        <span v-else>Kanban</span>
      </v-tooltip>
    </v-btn>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState({
      preferencesSt: state => state.preferences
    })
  },
  methods: {
    // implementa un loading en lo que se consulta la información en el API
    ...mapMutations({
      setLoading: "leads/setLoading",
      setPreferencesSt: "setPreferences"
    }),
    // consulta los leads al API
    // y actualiza en Vuex el valor de lista de leads
    updatePreferences() {
      if (this.preferencesSt != null && this.preferencesSt != undefined) {
        this.setPreferencesSt({
          showKanbanFirst: !this.preferencesSt.showKanbanFirst
        });
      } else {
        this.setPreferencesSt({
          showKanbanFirst: true
        });
      }
    }
  }
};
</script>
